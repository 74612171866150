<style scoped>
.search {
    width: 30%;
}
@media only screen and (max-width: 600px) {
  .search {
    width: 100%;
  }
}

.form__label {
  font-family: 'Roboto', sans-serif;
  font-size: 1.2rem;
  margin-left: 2rem;
  margin-top: 0.7rem;
  display: block;
  transition: all 0.3s;
  transform: translateY(0rem);
}

.form__input {
  font-family: 'Roboto', sans-serif;
  color: #333;
  font-size: 1.2rem;
	margin: 0 auto;
  padding: 1.5rem 2rem;
  border-radius: 0.2rem;
  background-color: rgb(255, 255, 255);
  border: none;
  width: 90%;
  display: block;
  border-bottom: 0.3rem solid transparent;
  transition: all 0.3s;
}

.form__input:placeholder-shown + .form__label {
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateY(-4rem);
  transform: translateY(-4rem);
}
</style>
<template>
    <div v-loading.fullscreen.lock="loading">

        <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8" align="right">
            <!-- <el-button type="primary" @click="$router.push('/add-kiosk');" size="mini">Шинэ киоск бүртгэх</el-button> -->
        </base-header>

        <div class="container-fluid mt--7">
            <div class="row">
                <div class="col">
                    <div class="card shadow border-0 p-5">
                        <div align="right">
                            <table>
                                <tr>
                                    <td><strong>Киоскын дугаар: </strong></td>
                                    <td style="padding-left: 10px;" colspan="2">{{detailNumber}}</td>
                                </tr>
                                <tr>
                                    <td><strong>Ажиллах цаг: </strong></td>
                                    <td style="padding-left: 10px;">{{detail.poweron}} - {{detail.poweroff}}</td>
                                    <td><base-button style="margin-left: 10px;" @click="modals.changeTime = true" type="primary" size="sm"><span class="ni ni-settings"></span></base-button></td>
                                </tr>
                            </table>
                        </div>
                        <div class="table-responsive mt-2">
                            <el-tabs v-model="activeTag">
                                <el-tab-pane label="Photo" name="photos">
                                    <base-table class="table align-items-center table-flush thead-light"
                                            tbody-classes="list"
                                            :data="photos">
                                    <template slot="columns">
                                        <th :style="{'background': '#F6F9FC'}">#Дугаар</th>
                                        <th :style="{'background': '#F6F9FC'}">Зураг</th>
                                        <th :style="{'background': '#F6F9FC'}">Эхлэх огноо</th>
                                        <th :style="{'background': '#F6F9FC'}">Дуусах огноо</th>
                                        <th :style="{'background': '#F6F9FC'}">Файлын хэмжээ</th>
                                        <th :style="{'background': '#F6F9FC', 'text-align': 'right'}">Төлөв</th>
                                        <th :style="{'background': '#F6F9FC'}"></th>
                                    </template>
                                    <template slot-scope="{row}">
                                        <th scope="row">
                                            <div class="media align-items-center">
                                                <div class="media-body">
                                                    {{row.id}}
                                                </div>
                                            </div>
                                        </th>
                                        <th scope="row">
                                            <div class="media align-items-center">
                                                <div class="media-body">
                                                    <el-image 
                                                        style="width: 40px; height: auto"
                                                        :src="$appUrl + '/contents/image/slide/'+row.name" 
                                                        :preview-src-list="[$appUrl + '/contents/image/slide/'+row.name]">
                                                    </el-image>
                                                </div>
                                            </div>
                                        </th>
                                        <td scope="row">
                                            <div class="media align-items-center">
                                                <div class="media-body">
                                                    <span class="name mb-0 text-sm">{{row.created_at}}</span>
                                                </div>
                                            </div>
                                        </td>
                                        <td scope="row">
                                            <div class="media align-items-center">
                                                <div class="media-body">
                                                    <span class="name mb-0 text-sm">{{row.end_at}}</span>
                                                </div>
                                            </div>
                                        </td>
                                        <td scope="row">
                                            <div class="media align-items-center">
                                                <div class="media-body">
                                                    <span class="name mb-0 text-sm">{{row.size}} MB</span>
                                                </div>
                                            </div>
                                        </td>
                                        <td scope="row" align="right">
                                            <div class="media align-items-center">
                                                <div class="media-body">
                                                    <badge class="badge-dot" type="success" v-if="row.active">
                                                        <i :class="`bg-completed`"></i>
                                                        <span class="status">Идэхвитэй</span>
                                                    </badge>
                                                    <badge class="badge-dot" type="danger" v-else>
                                                        <i :class="`bg-delayed`"></i>
                                                        <span class="status">Хугацаа дууссан</span>
                                                    </badge>
                                                </div>
                                            </div>
                                        </td>
                                        <td align="right">
                                            <el-tooltip class="item" effect="dark" content="Устгах" placement="top">
                                                <base-button type="danger" @click="deletePhoto(row.id)" size="sm"><span class="ni ni-fat-remove"></span></base-button>
                                            </el-tooltip>
                                        </td>
                                    </template>
                                    </base-table>
                                </el-tab-pane>
                                <el-tab-pane label="Video" name="videos">
                                    <base-table class="table align-items-center table-flush thead-light"
                                            tbody-classes="list"
                                            :data="videos">
                                <template slot="columns">
                                    <th :style="{'background': '#F6F9FC'}">#Дугаар</th>
                                    <th :style="{'background': '#F6F9FC'}">Бичлэг</th>
                                    <th :style="{'background': '#F6F9FC'}">Эхлэх огноо</th>
                                    <th :style="{'background': '#F6F9FC'}">Дуусах огноо</th>
                                    <th :style="{'background': '#F6F9FC'}">Файлын хэмжээ</th>
                                    <th :style="{'background': '#F6F9FC', 'text-align': 'right'}">Төлөв</th>
                                    <th :style="{'background': '#F6F9FC'}"></th>
                                </template>
                                <template slot-scope="{row}">
                                    <th scope="row">
                                        <div class="media align-items-center">
                                            <div class="media-body">
                                                {{row.id}}
                                            </div>
                                        </div>
                                    </th>
                                    <th scope="row">
                                        <div class="media align-items-center">
                                            <div class="media-body">
                                                <!-- <el-image 
                                                    style="width: 40px; height: auto"
                                                    :src="$appUrl+'/contents/image/slide/'+row.name" 
                                                    :preview-src-list="[$appUrl+'/contents/image/slide/'+row.name]">
                                                </el-image> -->
                                                {{row.name}}
                                            </div>
                                        </div>
                                    </th>
                                    <td scope="row">
                                        <div class="media align-items-center">
                                            <div class="media-body">
                                                <span class="name mb-0 text-sm">{{row.created_at}}</span>
                                            </div>
                                        </div>
                                    </td>
                                    <td scope="row">
                                        <div class="media align-items-center">
                                            <div class="media-body">
                                                <span class="name mb-0 text-sm">{{row.end_at}}</span>
                                            </div>
                                        </div>
                                    </td>
                                    <td scope="row">
                                        <div class="media align-items-center">
                                            <div class="media-body">
                                                <span class="name mb-0 text-sm">{{row.size}} MB</span>
                                            </div>
                                        </div>
                                    </td>
                                    <td scope="row" align="right">
                                        <badge class="badge-dot" type="success" v-if="row.active">
                                            <i :class="`bg-completed`"></i>
                                            <span class="status">Идэхвитэй</span>
                                        </badge>
                                        <badge class="badge-dot" type="danger" v-else>
                                            <i :class="`bg-delayed`"></i>
                                            <span class="status">Хугацаа дууссан</span>
                                        </badge>
                                    </td>
                                    <td align="right">
                                        <el-tooltip class="item" effect="dark" content="Тоглуулах" placement="top">
                                            <base-button type="info" @click="playVideo(row)" size="sm"><span class="ni ni-button-play"></span></base-button>
                                        </el-tooltip>
                                        <el-tooltip class="item" effect="dark" content="Устгах" placement="top">
                                            <base-button type="danger" @click="deleteVideo(row.id)" size="sm"><span class="ni ni-fat-remove"></span></base-button>
                                        </el-tooltip>
                                    </td>
                                </template>
                                </base-table>
                                </el-tab-pane>
                                <el-tab-pane label="Text" name="texts">
                                    <base-table class="table align-items-center table-flush thead-light"
                                            tbody-classes="list"
                                            :data="texts">
                                    <template slot="columns">
                                        <th :style="{'background': '#F6F9FC'}">#Дугаар</th>
                                        <th :style="{'background': '#F6F9FC'}">Текст</th>
                                        <th :style="{'background': '#F6F9FC'}">Эхлэх огноо</th>
                                        <th :style="{'background': '#F6F9FC'}">Дуусах огноо</th>
                                        <th :style="{'background': '#F6F9FC', 'text-align': 'right'}">Төлөв</th>
                                        <th :style="{'background': '#F6F9FC'}"></th>
                                    </template>
                                <template slot-scope="{row}">
                                    <th scope="row">
                                        <div class="media align-items-center">
                                            <div class="media-body">
                                                {{row.id}}
                                            </div>
                                        </div>
                                    </th>
                                    <th scope="row">
                                        <div class="media align-items-center">
                                            <div class="media-body">
                                                <!-- <el-image 
                                                    style="width: 40px; height: auto"
                                                    :src="$appUrl+'/contents/image/slide/'+row.name" 
                                                    :preview-src-list="[$appUrl+'/contents/image/slide/'+row.name]">
                                                </el-image> -->
                                                {{row.text}}
                                            </div>
                                        </div>
                                    </th>
                                    <td scope="row">
                                        <div class="media align-items-center">
                                            <div class="media-body">
                                                <span class="name mb-0 text-sm">{{row.created_at}}</span>
                                            </div>
                                        </div>
                                    </td>
                                    <td scope="row">
                                        <div class="media align-items-center">
                                            <div class="media-body">
                                                <span class="name mb-0 text-sm">{{row.end_at}}</span>
                                            </div>
                                        </div>
                                    </td>
                                    <td scope="row" align="right">
                                        <badge class="badge-dot" type="success" v-if="row.active">
                                            <i :class="`bg-completed`"></i>
                                            <span class="status">Идэхвитэй</span>
                                        </badge>
                                        <badge class="badge-dot" type="danger" v-else>
                                            <i :class="`bg-delayed`"></i>
                                            <span class="status">Хугацаа дууссан</span>
                                        </badge>
                                    </td>
                                    <td align="right">
                                        <el-tooltip class="item" effect="dark" content="Устгах" placement="top">
                                            <base-button type="danger" @click="deleteText(row.id)" size="sm"><span class="ni ni-fat-remove"></span></base-button>
                                        </el-tooltip>
                                    </td>
                                </template>
                                </base-table>
                                </el-tab-pane>
                                <el-tab-pane label="Logs" name="log">
                                    <table class="table align-items-center table-flush thead-light">
                                        <thead>
                                            <th :style="{'background': '#F6F9FC'}">#Дугаар</th>
                                            <th :style="{'background': '#F6F9FC'}">Шалгасан огноо</th>
                                            <th :style="{'background': '#F6F9FC'}">Төлөв</th>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(row, index) in currentSlide" :key="index">
                                                <th scope="row">
                                                    <div class="media align-items-center">
                                                        <div class="media-body">
                                                            {{index + 1}}
                                                        </div>
                                                    </div>
                                                </th>
                                                <td scope="row">
                                                    <div class="media align-items-center">
                                                        <div class="media-body">
                                                            <span class="name mb-0 text-sm">{{row.date}}</span>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td scope="row">
                                                    <div class="media align-items-center">
                                                        <div class="media-body">
                                                            <span class="name mb-0 text-sm" v-if="row.live"><span style="color: green;">Онлайн</span></span>
                                                            <span class="name mb-0 text-sm" v-else><span style="color: red;">Оффлайн</span></span>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div align="center" style="padding-top: 20px;">
                                        <el-pagination background layout="prev, pager, next" @current-change="currantPageChanged" :page-size="20" :total="logs.length"> </el-pagination>
                                    </div>
                                </el-tab-pane>
                            </el-tabs>
                        </div>
                    </div>
                    <!-- <span class="mt-3" @click="getProduct">
                        <base-pagination :page-count="pagination" class="mt-3" v-model="page"></base-pagination>
                    </span> -->
                </div>
            </div>
        </div>

        <modal :show.sync="modals.playvideo">
            <h6 slot="header" class="modal-title" id="modal-title-default">Тоглуулагч</h6>
              <div class="row">
                <video id="homevideo" width="100%" ref="homevideo" controls>
                    <source :src="currentVideo.src" :type="currentVideo.type">
                </video>
              </div>
        </modal>
        <modal :show.sync="modals.changeTime">
            <h6 slot="header" class="modal-title" id="modal-title-default">Ажиллах цаг солих</h6>
              <div>
                <el-row :gutter="10">
                    <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="mt-10">
                        <el-time-select style="width: 100%;"
                        v-model="runtime.poweron"
                        :picker-options="{
                            start: '06:00',
                            step: '00:10',
                            end: '23:30'
                        }"
                        placeholder="Асах цаг">
                        </el-time-select>
                    </el-col>
                    <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="margin-top: 10px;">
                        <el-time-select style="width: 100%;"
                        v-model="runtime.poweroff"
                        :picker-options="{
                            start: '06:00',
                            step: '00:10',
                            end: '23:30'
                        }"
                        placeholder="Унтрах цаг">
                        </el-time-select>
                    </el-col>
                </el-row>
              </div>
              <template slot="footer">
                <base-button type="link" class="ml-auto" @click="modals.changeTime = false">Гарах</base-button>
                <base-button type="primary" @click="changeKioskRunTime">Хадгалах</base-button>
              </template>
        </modal>
    </div>
</template>
<script>
  export default {
    data() {
        return {
            detailNumber: '',
            kiosk: [],
            videos: [],
            logs: [],
            texts: [],
            photos: [],
            loading: false,
            activeTag: 'photos',
            modals: {
                playvideo: false,
                changeTime: false
            },
            currentVideo: {
                src: '',
                type: ''
            },
            detail: [],
            runtime: {
                poweron: '',
                poweroff: ''
            },
            currentPage: 1
        }
    },
    computed: {
        currentSlide() {
            return this.logs.slice((this.currentPage - 1 ) * 20, this.currentPage * 20);
        }
    },
    mounted() {
        this.detailNumber = this.$route.params.id;
        this.getDetail();
    },
    methods: {
        currantPageChanged(page) {
            this.currentPage = page;
        },
        changeKioskRunTime() {
            var rts = this;
            this.loading = true;
            var token = localStorage.getItem('token');
            rts.$axios({
                method: 'POST',
                url: this.$appUrl + '/user/change-runtime',
                headers: {
                    "Authorization": `Bearer ${token}`
                },
                data: {
                    poweron: this.runtime.poweron,
                    poweroff: this.runtime.poweroff,
                    kiosk: this.detailNumber
                }
            }).then(data => {
                if(data.data.result == 200) {
                    rts.detail.poweron = rts.runtime.poweron;
                    rts.detail.poweroff = rts.runtime.poweroff;
                    rts.loading = false;
                    rts.modals.changeTime = false;
                    rts.$notify({
                        title: 'Амжилттай',
                        message: `Шинэчлэгдлээ`,
                        type: 'success'
                    });
                }
            });
        },
        deleteVideo(id) {
            this.$confirm('Хасахдаа итгэлтэй байна уу?', 'Санамж', {
                confirmButtonText: 'Тийм',
                cancelButtonText: 'Болих',
                type: 'warning'
            }).then(() => {
                var rts = this;
                this.loading = true;
                var token = localStorage.getItem('token');
                rts.$axios({
                    method: 'POST',
                    url: this.$appUrl + '/user/remove-video-from-kiosk',
                    headers: {
                        "Authorization": `Bearer ${token}`
                    },
                    data: {
                        id,
                        kiosk: this.detailNumber
                    }
                }).then(data => {
                    rts.loading = false;
                    if(data.data.result == 200) {
                        rts.videos.forEach((eleme, idx) => {
                            if(eleme.id == id) {
                                rts.videos.splice(idx, 1);
                            }
                        });
                        rts.$notify({
                            title: 'Амжилттай',
                            message: `Бичлэг хасагдлаа`,
                            type: 'success'
                        });
                    } else {
                        rts.$notify({
                            title: 'Амжилтгүй',
                            message: `Алдаа гарлаа`,
                            type: 'warning'
                        });
                    }
                }).catch(error => {
                    rts.loading = false;
                    console.log(error);
                });
            });
        },
        deleteText(id) {
            this.$confirm('Хасахдаа итгэлтэй байна уу?', 'Санамж', {
                confirmButtonText: 'Тийм',
                cancelButtonText: 'Болих',
                type: 'warning'
            }).then(() => {
                var rts = this;
                this.loading = true;
                var token = localStorage.getItem('token');
                rts.$axios({
                    method: 'POST',
                    url: this.$appUrl + '/user/remove-text-from-kiosk',
                    headers: {
                        "Authorization": `Bearer ${token}`
                    },
                    data: {
                        id,
                        kiosk: this.detailNumber
                    }
                }).then(data => {
                    rts.loading = false;
                    if(data.data.result == 200) {
                        rts.texts.forEach((eleme, idx) => {
                            if(eleme.id == id) {
                                rts.texts.splice(idx, 1);
                            }
                        });
                        rts.$notify({
                            title: 'Амжилттай',
                            message: `Текст хасагдлаа`,
                            type: 'success'
                        });
                    } else {
                        rts.$notify({
                            title: 'Амжилтгүй',
                            message: `Алдаа гарлаа`,
                            type: 'warning'
                        });
                    }
                }).catch(error => {
                    rts.loading = false;
                    console.log(error);
                });
            });
        },
        deletePhoto(id) {
            this.$confirm('Хасахдаа итгэлтэй байна уу?', 'Санамж', {
                confirmButtonText: 'Тийм',
                cancelButtonText: 'Болих',
                type: 'warning'
            }).then(() => {
                var rts = this;
                this.loading = true;
                var token = localStorage.getItem('token');
                rts.$axios({
                    method: 'POST',
                    url: this.$appUrl + '/user/remove-photo-from-kiosk',
                    headers: {
                        "Authorization": `Bearer ${token}`
                    },
                    data: {
                        id,
                        kiosk: this.detailNumber
                    }
                }).then(data => {
                    rts.loading = false;
                    if(data.data.result == 200) {
                        rts.photos.forEach((eleme, idx) => {
                            if(eleme.id == id) {
                                rts.photos.splice(idx, 1);
                            }
                        });
                        rts.$notify({
                            title: 'Амжилттай',
                            message: `Зураг хасагдлаа`,
                            type: 'success'
                        });
                    } else {
                        rts.$notify({
                            title: 'Амжилтгүй',
                            message: `Алдаа гарлаа`,
                            type: 'warning'
                        });
                    }
                }).catch(error => {
                    rts.loading = false;
                    console.log(error);
                });
            });
        },
        playVideo(row) {
            this.currentVideo.src = this.$appUrl + '/contents/video/' + row.name;
            this.currentVideo.type = row.extn;
            this.$refs.homevideo.load();
            this.modals.playvideo = true;
        },
        getDetail() {
            var rts = this;
            this.loading = true;
            var token = localStorage.getItem('token');
            rts.$axios({
                method: 'POST',
                url: this.$appUrl + '/kiosk/get-content',
                headers: {
                    "Authorization": `Bearer ${token}`
                },
                data: {
                    detailnumber: this.detailNumber
                }
            }).then(data => {
                console.log(data);
                rts.loading = false;
                if(data.data.status == 200) {
                    rts.photos = data.data.photos;
                    rts.videos = data.data.videos;
                    rts.texts = data.data.text;
                    rts.detail = data.data.k;
                    rts.runtime.poweron = data.data.k.poweron;
                    rts.runtime.poweroff = data.data.k.poweroff;
                    rts.logs = data.data.logfile;
                } else {
                    rts.$router.push('/kiosks');
                }
                
            }).catch(error => {
				rts.loading = false;
				console.log(error);
			});
        }
    }
  }
</script>
